import * as React from "react"
import {graphql} from "gatsby";
import AboutNavigation from "../components/about/aboutNavigation";
import InnerLayout from "../components/global/innerLayout"
import PageTitle from "../components/global/pageTitle";
import parse from "html-react-parser";
import "./about-legal.scss";

export const query = graphql`
    query($id: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "aboutUsLegalPage", id: $id) {
          ... on Craft_aboutUsLegalPage_default_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            title
            uri
            legalPageBody
            showSidebar
          }
        }
      }
    }
  `

const AboutLegalPage = ({ data, pageContext }) => {

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd about-legal-page">

                <PageTitle title={data.craft.page.title} />

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row padding-top-none">
                            { data.craft.page.showSidebar &&
                                <div className="sidebar">
                                    <AboutNavigation active={data.craft.page.uri} />
                                </div>
                            }
                            <div className={data.craft.page.showSidebar ? 'content-right' : 'content'}>

                                {parse(data.craft.page.legalPageBody)}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default AboutLegalPage
